import React, { useState, useRef, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import get from "lodash/get"
import Slider from "react-slick"
import { useQueryParamString } from "react-use-query-param-string"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import Banner from "../components/Banner/Banner"
import RichText from "../components/RichText"
import Image from "../components/Image/Image"
import RecipeFilterModal from "../components/Modals/RecipeFilterModal"
import Button from "../components/Button/Button"
import BlockList from "../components/PageLayout/BlockList"
import RecipeResults from "../components/Listing/RecipeListing/RecipeResults"

import SearchIcon from "../images/svg/icon_search.svg"
import CloseIcon from "../images/svg/icon_close.svg"

import { useLocale } from "../context/LocaleContext"
import { getPlainText, getPrettyURL, isEmptyArray } from "../utils"
import { RecipeListingModel, RecipeModel } from "../models/pages"
import { SeoDataModel } from "../models/common"
import { TagModel } from "../models/components"
import { TaxonomyListModel } from "../components/Modals/RecipeFilterModal"
import useDictionary from "../hooks/useDictionary"

import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

const sortRecepies = (recepies: RecipeModel[]) => {
    let newRecepies = [...recepies];
    newRecepies = newRecepies?.sort((a: RecipeModel, b: RecipeModel) => {
      if (!b.updated_at) return -1
      if (!a.updated_at) return -1
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    });

    return newRecepies;
}

export const RecipeListingPage: React.FC<RecipeListingModel> = props => {
  // page data
  const _page =  get(props, "data.page") as any as RecipeListingModel;
  _page.pageContext = props.pageContext

  const _taxonomies = get(props, "data.taxonomies.nodes") as any as TagModel[];
  const _recipes: RecipeModel[] = sortRecepies(
    get(props, "data.recipes.nodes") as any as RecipeModel[]
  );
  const [page, setPage] = useState(_page);
  const [taxonomies, setTaxonomies] = useState(_taxonomies);
  const [recipes, setRecipes] = useState(_recipes);

  useEffect(() => {
    contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext))
    contentstackLivePreviewWrapper(fetchLivePreviewData(_taxonomies, setTaxonomies))
    contentstackLivePreviewWrapper(fetchLivePreviewData(_recipes, setRecipes, null, sortRecepies))
}, []) 


  // context
  const { localeDir } = useLocale().localeData

  // query params
  const [categoryParam, setCategoryParam] = useQueryParamString("category", "")
  const [searchParam, setSearchParam] = useQueryParamString("s", "")
  const [pageParam, setPageParam] = useQueryParamString("p", "")

  // state
  const [taxonomyList, setTaxonomyList] = useState<TaxonomyListModel[]>([])
  const [filters, setFilters] = useState<string[] | undefined>(undefined)
  const [modalOpen, setModalOpen] = useState(false)

  // refs
  const searchRef = useRef<HTMLInputElement>(null)

  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  const navLabel = getPlainText(page.nav_label)

  // SEO Fields
  const seoData: SeoDataModel = {
    metaTitle: page.seo?.title || heroHeading,
    metaDescription:
      page.seo?.description || getPlainText(page.listing?.excerpt),
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    pageContext: props.pageContext,
  }

  useEffect(() => {
    if (!taxonomyList.length && taxonomies && page.search_taxonomy) {
      let result: TaxonomyListModel[] = []
      page.search_taxonomy.forEach(taxonomy =>
        result.push({
          pageContext: props.pageContext,
          label: taxonomy,
          tags: taxonomies
            .filter((x: TagModel) => x.taxonomy === taxonomy)
            .sort((a: TagModel, b: TagModel) => {
              if (a.order === b.order) {
                return a.display_name
                  .toLowerCase()
                  .localeCompare(b.display_name.toLowerCase())
              }
              return (a.order || 0) > (b.order || 0) ? 1 : -1
            }),
        })
      )
      setTaxonomyList(result)
    }
  }, [page.search_taxonomy, taxonomies])

  useEffect(() => {
    if (filters) {
      setCategoryParam(filters.join(","))
      setPageParam("")
    }
  }, [filters])

  useEffect(() => {
    if (categoryParam) setFilters(categoryParam.split(","))
  }, [categoryParam])

  // events
  const handleSearchButton = () => {
    if (searchRef?.current) {
      setSearchParam(searchRef.current.value)
      navigate(`/${localeDir}/recipes/?s=${searchRef.current.value}`)
    }
  }

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchRef?.current) {
      setSearchParam(searchRef.current.value)
      navigate(`/${localeDir}/recipes/?s=${searchRef.current?.value || ""}`)
    }
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleSubmit = (filters: string[]) => {
    setFilters(filters)
    closeModal()
  }

  const handleRemoveFilter = (term: string) => {
    if (filters?.includes(term)) setFilters(filters.filter(x => x !== term))
    else setFilters([...(filters || []), term])
    closeModal()
  }

  const sliderOptions = {
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  }

  const advancedSearchText = useDictionary(
      "Advanced Search",
      props.pageContext.locale
  )

  const searchPlaceHolder = useDictionary(
      "Search over 900 recipes",
      props.pageContext.locale
  )

  return (
    <div className="recipeListingPage">
      <Seo {...seoData} />

      {/* Breadcrumbs */}
      <Breadcrumbs
        breadcrumbs={page.url_structure}
        currentPage={navLabel || heroHeading}
        pageContext={props.pageContext}
      />

      {/* Header */}
      <section className="hero with-sidebar">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-auto">
              <div className="page__body">
                <h1>{heroHeading}</h1>

                <RichText
                  content={page.introduction}
                  cssClass="content"
                  pageContext={props.pageContext}
                />

                {page.health_images && page.health_images.length > 0 && (
                  <div className="recipeIcons">
                    {page.health_images.map(image => (
                      <div key={image.id} className="recipeIcon">
                        <Image image={image} />
                      </div>
                    ))}
                  </div>
                )}

                <div className="search">
                  <div className="grid-x grid-margin-x align-middle">
                    <div className="cell medium-auto">
                      <div className="grid-x align-middle searchBox">
                        <div className="cell auto">
                          <input
                            type="search"
                            className="search__input"
                            placeholder={searchPlaceHolder}
                            onKeyUp={handleSearch}
                            ref={searchRef}
                            defaultValue={searchParam}
                          />
                        </div>
                        <div className="cell shrink">
                          <button
                            className="button"
                            onClick={handleSearchButton}
                          >
                            <span className="show-for-medium">
                              {useDictionary(
                                "Find recipes",
                                props.pageContext.locale
                              )}
                            </span>
                            <SearchIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="cell medium-shrink">
                      <Button
                        label={advancedSearchText}
                        variant="Secondary"
                        onClick={openModal}
                        pageContext={props.pageContext}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cell large-shrink show-for-large">
              <div className="page__sidebar">
                {!isEmptyArray(page.sidebar) && (
                  <Slider {...sliderOptions}>
                    {page.sidebar?.map(banner => (
                      <Banner
                        key={banner.id}
                        {...banner}
                        pageContext={props.pageContext}
                      />
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Search Filters */}
      {filters && filters.length > 0 && (
        <section className="searchFilters">
          <div className="grid-container">
            <div className="grid-x">
              <div className="cell shrink">
                {useDictionary("DISPLAYING", props.pageContext.locale)}: &nbsp;
              </div>
              <div className="cell auto">
                <ul className="filter__list" style={{ marginTop: -1 }}>
                  {filters.map(filter => (
                    <li key={filter} className="filter__item">
                      {filter}
                      <button
                        className="filter__button"
                        onClick={() => handleRemoveFilter(filter)}
                      >
                        <CloseIcon width="10px" height="10px" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}

      <RecipeResults
        items={recipes}
        searchTerm={searchParam}
        pageContext={props.pageContext}
      />

      {page.blocks && (
        <BlockList items={page.blocks} pageContext={props.pageContext} />
      )}

      <RecipeFilterModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        taxonomyList={taxonomyList}
        selectedItems={filters}
        handleSubmit={handleSubmit}
        pageContext={props.pageContext}
      />
    </div>
  )
}

RecipeListingPage.defaultProps = defaultProps

export default RecipeListingPage

/******************************************************
	Data
******************************************************/
export const query = graphql`
  query RecipeListingPageByID(
    $pageId: String!
    $searchTaxonomy: [String]
    $locale: String
  ) {
    page: contentstackRecipeListing(id: { eq: $pageId }) {
      __typename
      uid
      ...RecipeListingFields
      hero {
        hide_breadcrumbs
      }
      introduction
      health_images {
        ...CmsImageFields
        gatsbyImageData(quality: 95)
      }
      search_taxonomy
      sidebar {
        ... on Contentstack_banner {
          ...BannerFields
        }
      }
      blocks {
        ...AllBlocks
      }
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }

    recipes: allContentstackRecipe(
      filter: {
        enable_sites: { sites: { in: "sanitarium" } }
        publish_details: { locale: { eq: $locale } }
        title: { ne: "do not delete" }
      }
    ) {
      nodes {
        ...RecipeFields
      }
    }

    taxonomies: allContentstackTag(
      filter: {
        taxonomy: { in: $searchTaxonomy }
        publish_details: { locale: { eq: $locale } }
        title: { ne: "do not delete" }
      }
    ) {
      nodes {
        ...TagFields
      }
    }
  }

  fragment RecipeListingFields on Contentstack_recipe_listing {
    id
    url
    url_structure {
      ...UrlStructureFields
    }
    hero {
      heading
      image_focus
      hero_image {
        ...HeroImageFields
      }
    }
    listing {
      excerpt
      listing_img: listing_image {
        ...CmsImageFields
        gatsbyImageData(width: 590, quality: 95)
      }
      mini_img: listing_image {
        ...CmsImageFields
        gatsbyImageData(height: 75, quality: 95)
      }
      darken_image
      listing_color
      listing_width
      promo_size
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`
