import React, { useState, useRef, useEffect } from "react"
import Modal from "react-modal"
import { Scrollbars } from "react-custom-scrollbars-2"

import Accordion from "../Accordion"
import Button from "../Button/Button"

import CloseIcon from "../../images/svg/icon_close.svg"
import TickIcon from "../../images/svg/icon_tick.svg"

import { TagModel } from "../../models/components"
import useDictionary from "../../hooks/useDictionary"

interface Props {
  modalOpen: boolean
  closeModal: () => void
  taxonomyList?: TaxonomyListModel[]
  selectedItems?: string[]
  handleSubmit: (x: string[]) => void
  pageContext: any
}

export interface TaxonomyListModel {
  label: string
  tags: TagModel[]
  pageContext: any
}

const defaultProps = {}

Modal.setAppElement("#___gatsby")

export const RecipeFilterModal: React.FC<Props> = props => {
  // state
  const [headerHeight, setHeaderHeight] = useState<number>()
  const [contentHeight, setContentHeight] = useState<number>()
  const [footerHeight, setFooterHeight] = useState<number>()
  const [filters, setFilters] = useState<string[]>([])

  // refs
  const headerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  // effects
  useEffect(() => {
    setTimeout(() => {
      if (headerRef.current) setHeaderHeight(headerRef.current.scrollHeight)
      if (contentRef.current) setContentHeight(contentRef.current.scrollHeight)
      if (footerRef.current) setFooterHeight(footerRef.current.scrollHeight)
    }, 50)
  }, [props.modalOpen])

  useEffect(() => {
    if (props.selectedItems) setFilters(props.selectedItems)
  }, [props.selectedItems])

  // events
  const handleSelect = (term: string) => {
    if (filters.includes(term)) setFilters(filters.filter(x => x !== term))
    else setFilters([...filters, term])
  }

  const handleClear = () => {
    setFilters([])
  }

  const handleSubmit = () => {
    props.handleSubmit(filters)
  }

  return (
    <Modal
      contentLabel={useDictionary("Advanced Search", props.pageContext.locale)}
      isOpen={props.modalOpen}
      className="modal__recipeFilter"
      onRequestClose={props.closeModal}
      closeTimeoutMS={200}
    >
      <div className="modal__header" ref={headerRef}>
        <h2>{useDictionary("Advanced Search", props.pageContext.locale)}</h2>
      </div>
      <div className="modal__content">
        <Scrollbars
          autoHide
          style={{
            height: `min(40vh, 80vh - ${
              (headerHeight || 0) + (footerHeight || 0)
            }px - 80px)`,
          }}
        >
          <div className="modal__scroller" ref={contentRef}>
            {props.taxonomyList?.map(list => (
              <Accordion
                key={list.label}
                heading={list.label}
                arrowColor="#003da5"
              >
                <ul className="list">
                  {list.tags.map(tag => (
                    <li
                      key={tag.id}
                      className={`list__item ${
                        filters.includes(tag.display_name) ? "selected" : ""
                      }`}
                    >
                      <TickIcon
                        className="list__tick"
                        width="16px"
                        height="16px"
                        fill="#b6cf54"
                      />
                      <button
                        className={`list__link`}
                        onClick={() => handleSelect(tag.display_name)}
                      >
                        {tag.display_name}
                      </button>
                    </li>
                  ))}
                </ul>
              </Accordion>
            ))}
          </div>
        </Scrollbars>
      </div>
      <div className="modal__footer" ref={headerRef}>
        <div className="modal__cta">
          <Button
            label={useDictionary("Clear all", props.pageContext.locale)}
            onClick={handleClear}
            pageContext={props.pageContext}
          ></Button>
          <Button
            label={useDictionary("Apply selection", props.pageContext.locale)}
            variant="Secondary"
            onClick={handleSubmit}
            pageContext={props.pageContext}
          ></Button>
        </div>
      </div>
      <button className="modal__close" onClick={props.closeModal}>
        <CloseIcon width="20" height="20" />
        <span className="show-for-sr">{useDictionary("close", props.pageContext.locale)}</span>
      </button>
    </Modal>
  )
}

RecipeFilterModal.defaultProps = defaultProps

export default RecipeFilterModal
